.player-widget {
    position: fixed;
    bottom: 0;
    left: 240px;
    width: calc(100% - 490px);
    z-index: 4;
}

@media (max-width: 1700px) {
    .player-widget {
        width: calc(100% - 240px);
    }
}

@media (max-width: 1300px) {
    .player-widget {
        left: 0;
        width: calc(100%);
    }
}

.player-widget-inner {
    transform: translateY(100%);
    visibility: hidden;
    transition: all 0.7s ease-out;
    background-color: #e5f3ff; /* #F6FBFF;*/
    padding: 7.5px 35px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 0px;
    /* box-shadow: 0 4px 7px rgba(0, 0, 0, 0.20), 0 1px 7px rgba(0, 0, 0, 0.20); */
}

@media (max-width: 767px) {
  .player-widget-inner {
    padding: 7.5px 15px;
    justify-content: center;
  }
}

.player-widget-inner .radio-icon {
    width: 45px;
    height: 45px;
    margin: 0 20px;
}

@media (max-width: 480px) {
  .player-widget-inner .radio-icon {
    margin: 0 12px;
  }
}

.player-widget-inner .radio-icon > img {
    width: 45px;
    height: 45px;
    border-radius: 5px;
}

.player-widget-inner .action {
    width: 45px;
    height: 45px;
    cursor: pointer;
    position: relative;
}

@media (max-width: 480px) {
  .player-widget-inner .action {
    width: 40px;
    height: 40px;
  }
}

.player-widget-inner .action .icon {
    width: 45px;
    height: 45px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media (max-width: 480px) {
  .player-widget-inner .action .icon {
    width: 40px;
    height: 40px;
  }
}

.player-widget-inner .action .play-pause {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media (max-width: 480px) {
  .player-widget-inner .action .play-pause {
    width: 27px;
    height: 27px;
  }
}


.player-widget-inner .action .icon.is-playing {
    animation: Rotate infinite 4s linear;
}


/* .player-widget-inner .action svg {
    width: calc(100% - 2px);
    height: calc(100% - 2px);
} */

.player-widget-inner .action svg > * {
    stroke: var(--primary-color);
    stroke-width: 1.5;
    fill: var(--primary-color); 
}

.player-widget-inner .action svg.play-pause path {
    fill: white;
  }

.player-widget-inner.active {
    transform: translateY(0);
    visibility: visible;
}

@keyframes Rotate {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  .radio.is-favourite {
    width: 26px;
    height: 26px;
    background-color: var(--primary-color);
    border-radius: 5px;
    padding: 4px;
    cursor: pointer;
    margin-right: 10px;
  }

  @media (max-width: 480px) {
    .radio.is-favourite {
      width: 22px;
      height: 22px;
      padding: 3px;
      margin-right: 6px;
    }
  }

  .radio.is-favourite svg {
    width: 26px;
    height: 26px;
  }

  @media (max-width: 480px) {
    .radio.is-favourite svg{
      width: 22px;
      height: 22px;
    }
  }

  .radio.is-favourite:not(.selected) svg path {
    fill: transparent;
    stroke: #FFFFFF;
    stroke-width: 1.8;
  }

  .radio.is-favourite.selected svg > * {
    stroke: #FFFFFF;
    stroke-width: 1;
    fill: var(--primary-color);
  }

  .radio.is-favourite.selected svg path {
    fill: white;
    stroke-width: 0;
  }


  .radio-broken {
    width: 24px;
    height: 24px;
    background-color: var(--primary-color);
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
  }

@media (max-width: 480px) {
  .radio-broken {
    width: 22px;
    height: 22px;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 480px) {
    .radio-broken img {
      width: 19px;
      height: 19px;
  }
}