:root {
  --primary-color: #0D5EAF;
  --primary-color-active: #0e4f26;
  --primary-color-hover:#76db9b;
}

body {
  margin: 0;
  /* font-family: 'Poppins', sans-serif; */
  font-family: 'Comfortaa', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  height: 100%;
}

#app {
  height: 100%;
}

*:focus-visible {
  outline: none;
}

.page-layout-wrapper {
  height: 100%;

  display: grid;
  grid-template-areas: "head head head"
                       "side content shortcut";
  grid-template-columns: 240px calc(100% - 490px) 250px;
  grid-template-rows: 70px calc(100% - 70px);
}

@media (max-width: 1700px){
  .page-layout-wrapper {
    grid-template-areas: 
      "head"
      "side content";
    grid-template-columns: 240px 1fr;
    grid-template-rows: 70px auto;
  }
}

@media (max-width: 1300px){
  .page-layout-wrapper {
    grid-template-areas: 
      "head"
      "side content";
    grid-template-columns: 70px 1fr;
    grid-template-rows: 70px auto;
  }
}

@media (max-width: 600px){
  .page-layout-wrapper {
    grid-template-areas: 
      "head"
      "content";
    grid-template-columns: 1fr;
    grid-template-rows: 70px auto;
  }
}

.page-layout {
  height: calc(100% - 70px);
  padding-top: 70px;
  background-color: #FFFFFF;                    
}

.main {
  position: relative;
  box-sizing: border-box; 
  padding: 30px 35px 100px; 
  grid-area: content;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

@media (max-width: 600px) {
  .main {
      padding: 20px 10px 100px 25px;
  }
  
  .main.mob {
    padding: 20px 25px 100px;
  }
}

.main-inner {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1400;
  background-color: #393939; 
  opacity: 0.8;
  animation: backgroundAnimation ease-in 300ms;
}

@keyframes backgroundAnimation {
  0% { background-color: transparent;}
  100% { background-color: #393939;}
}

.main::-webkit-scrollbar {
  width: 15px;
  border-left: 1px solid #CDCACA;
  border-right: 1px solid #CDCACA;
}

.main::-webkit-scrollbar-thumb {
  cursor: pointer; 
  border-radius: 10px;
  background-color: #CDCACA;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  background-clip: content-box;
}

.main::-webkit-scrollbar-track {
  background-color: #FFFBFB;
  /* border-left: 1px solid #CDCACA;
  border-right: 1px solid #CDCACA; */
}

.page-layout .main {
  float: left;
  width: 100%; /* calc(100% - 492px);*/
  height: 100%;
  box-sizing: border-box; 
  padding: 30px 35px 25px; 
  grid-area: content;
}

.page-layout .shortcut-wrapper {
  grid-area: shortcut;
  float: left;
  width:250px;
  height: 100%;
  background-color: #FFFFFF;
  /* position: fixed;
  top: 0;
  bottom: 0;
  right: 0; */
  overflow-x: hidden;
  z-index: 1;
  
}

.grid-container {
  height: 100%;
}


.grid-item {
  float: left;
  width: 12.5%;
}

.grid-item-inner {
  margin: 30px 25px;
}

.skeleton-wrapper {
  display: block;
  position: relative;
  overflow: hidden;
  width: 120px;
}

.scrollable-content .skeleton-wrapper {
  width: auto;
}

.scrollable-content .skeleton-image {
  width: 80px;
  height: 80px;
}

.skeleton {
  display: block;
  position: relative;
  overflow: hidden;
  background-color: #DDDBDD;
}

.skeleton-sm {
  width: 100px;
  height: 100px;
}

.skeleton-xs {
  width: 50px;
  height: 50px;
}

.skeleton-circle {
  border-radius: 50%;
}

.skeleton-row {
  height: 12px;
  border-radius: 3px;
  margin: 5px 10px;
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.skeleton-item {
  background-color: #F5F5F5;
  padding: 10px 0 10px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skeleton-image {
  width: 90px;  
  height: 90px;
  border-radius: 4px;
  margin: 0 10px 6px;
}


.skeleton::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  animation: shimmer 2s infinite; 
  content: '';
}

.skeleton-item .skeleton-row:last-child {
  margin: 6px 10px 0 12px;
}

/*Categories Page*/

.category-items-wrapper {
  display: flex;
  flex-direction:row;
  column-gap: 12px;
  row-gap: 14px;
  flex-wrap: wrap;
  padding: 3px 0 40px;
}

.category-items-wrapper .category-item {
  display: grid;
  place-items: center;
}

.category-items-wrapper .category-item a {
  text-decoration: none;
  user-select: none;
  color: inherit;
  background-color: #D0E5F8;
  color: var(--primary-color);
  padding: 9px 14px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 400;
}

.category-items-wrapper .category-item a.active,
.category-items-wrapper .category-item a:hover {
  cursor:pointer;
  background-color: var(--primary-color);
  color: #FFFFFF;
  font-weight: 500;
}

.skeleton-link-item {
  display: block;
  width: 100px;
  padding: 9px 14px;
  background-color: #ededed;
  border-radius: 5px;
  height: 14px;
}

/*Location Page*/

.location-items-wrapper {
  display: flex;
  flex-direction:row;
  column-gap: 12px;
  row-gap: 14px;
  flex-wrap: wrap;
  padding: 3px 0 40px;
}

.location-items-wrapper .location-item {
  display: grid;
  place-items: center;
}

.location-items-wrapper .location-item a {
  text-decoration: none;
  user-select: none;
  color: inherit;
  background-color: #D0E5F8;
  color: var(--primary-color);
  padding: 9px 14px;
  border-radius: 5px;
  font-size: 13px;
  font-weight: 400;
}

.location-items-wrapper .location-item a.active,
.location-items-wrapper .location-item a:hover {
  cursor:pointer;
  background-color: var(--primary-color);
  color: #FFFFFF;
  font-weight: 500;
}

.toast {
  border-radius: 3px !important;
  font-size: 13px;
  font-weight: 600 !important;
  padding: 6px 10px !important;
  background-color: #FFFFFF !important;
}

.page-title {
  color: #393939;
  font-size: 14px;
  font-weight: 800;
  padding: 10px 0 20px;
}