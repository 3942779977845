.music-bars {
    position: relative;
    display: block;
    width: 16px;
    height: 14px;
  }

  .bar {
    display: inline-block;
    width: 3px;
    height: 0px;
    background-color: var(--primary-color);
    margin: 0 0.5px;
  }

  .bar1 {
    animation: musicAnimation 0.4s infinite alternate 0s;
  }
  
  .bar2 {
    animation: musicAnimation 0.4s infinite alternate 0.3s;
  }
  
  .bar3 {
    animation: musicAnimation 0.4s infinite alternate 0.6s;
  }
  
  .bar4 {
    animation: musicAnimation 0.4s infinite alternate 0.9s;
  }

  @keyframes musicAnimation {
    0% {
      height: 0px;
    }
    100% {
      height: 12px;
    }
  }