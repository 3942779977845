.popular-widget {
    position: relative;
    padding: 0 0 50px;
}

.popular-widget .widget-header {
    display: flex;
    width: 100%;
    padding: 10px 0 10px;
    position: relative;
    align-items: center;
    justify-content: space-between;
}

.popular-widget .widget-title {
    font-size: 14px;
    font-weight: 800;
    color: #393939;
    display: block;
    line-height: 15px;
    height: 25px;
}

@media (max-width: 600px){
    .popular-widget .widget-title {
        line-height: 25px;
    }
}

.popular-widget .navigation-icons {
    display: flex;
    column-gap: 8px;
}

.scrollable-component{
    overflow: hidden;
}

.scrollable-content {
    display: flex;
    align-items: center;
    column-gap: 10px;
    scroll-snap-type: x mandatory;
    height: 100%;
    width: max-content;
    transform: translateX(0);
    transition: transform 0.3s ease;
    touch-action: none;
}

.scrollable-content:hover {
    cursor:grab;
}

.scrollable-content.dragging {
    cursor: grabbing;
}

.popular-item {
    width: 100px;
    position: relative;
    z-index: 1;
    scroll-snap-align: center;
    height: 100%;
    position: relative;
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.3s cubic-bezier(0.45, 0, 0.55, 1) 0s; */
}

.popular-item .image {
    width: 90px;
    height: 90px;
    padding: 5px;
    z-index: 2;
    position: relative;
}

.popular-item .is-favourite {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 24px;
    height: 24px;
    visibility: hidden;
    opacity: 0;
    transition: opacity ease-in 500ms;
    cursor: pointer;
    z-index: 3;
}

.popular-item:hover .action {
    visibility: visible;
    opacity: 1;
}

.popular-item .action svg {
    width: 100%;
    height: 100%;
}

.popular-item .action svg path {
    stroke:var(--primary-color);
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.8;
}

.popular-item .action svg > * {
    stroke: #FFFFFF;
    stroke-width: 1;
    fill: var(--primary-color);
  }
  
  .popular-item .action svg path {
    fill: white;
    stroke-width: 0;
  }

.popular-item .is-favourite svg path {
    fill: #FFFFFF;
}

.popular-item:hover .is-favourite {
    visibility: visible;
    opacity: 1;
  }

  .popular-item .is-favourite.selected svg > * {
    stroke: #FFFFFF;
    stroke-width: 1;
    fill: var(--primary-color);
  }

  .popular-item .is-favourite.selected svg path {
    fill: white;
    stroke-width: 0;
  }

  .popular-item .is-favourite:not(.selected) svg path {
    fill: transparent;
    stroke: #FFFFFF;
    stroke-width: 1.8;
  }

.popular-item.active .cover,  
.popular-item:hover .cover {
    position: absolute;
    top: 5px;
    left: 5px;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    background-color: rgba(57,57,57, 0.75);
    transition: background-color ease-in 500ms;
    z-index: 2;
    border-radius: 10px;
}

.popular-item img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    font-size: 13px;
}

.popular-item .name {
    text-align: center;
    font-weight: 500;
    font-size: 13px;
    padding: 0 0 4px;
    color: #787878;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.popular-item .category {
    text-align: left;
    font-weight: 400;
    font-size: 13px;
    padding: 0 4px;
    color: gray;
}

.scrollable-arrows {
    display: grid;
    place-items: center;
    width: 32px; 
    height: 25px; 
    background-color: #f6fbff;
    color: var(--primary-color);
    cursor: pointer;
    opacity: 1;
    transition: opacity 0.3s ease;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 800;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.10), 0 1px 7px rgba(0, 0, 0, 0.10);
  }

  .scrollable-arrows.disabled {
    opacity: 0.35;
    cursor: not-allowed;
  }

  .popular-item .action {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 36px;
    height: 36px;
    visibility: hidden;
    opacity: 0;
    transition: opacity ease-in 500ms;
    cursor: pointer;
    z-index: 3;
  }

  .popular-item .action.active {
    opacity: 1;
    visibility: visible;
    right: 10px;
  }

  .popular-item:hover .action:not(.playing) {
    animation-name: positionYAnimation;
    animation-duration: 200ms;
    animation-timing-function: ease-in-out;
  }

  @keyframes positionYAnimation {
    from {
      top: 65%;
    }
    to {
      top: 50%;
    }
  }