.input-search {
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 6px;
    box-sizing: border-box;
    color: rgba(0,0,0,.88);
    display: inline-block;
    font-size: 14px;
    line-height: 1.5714285714285714;
    min-width: 0;
    padding: 4px 11px;
    position: relative;
    transition: all .2s;
    width: 420px;
    line-height: 24px;
    font-family: Comfortaa, cursive;
    z-index: 5;
}

@media (max-width: 900px) {
    .input-search {
      width: 360px;
    }
}

.input-search.focus {
    border-radius: 0px 0px 0 0;
}

.input-search:focus {
    border-radius: 0px 0px 0 0;
}

.input-search::-moz-placeholder {
    color: rgba(0, 0, 0, 0.25);
}

.input-search::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.25);
}

.input-search::placeholder {
    color: rgba(0, 0, 0, 0.25);
}

.search-wrapper {
    position: relative;
    width: 420px;
    display: flex;
    flex-direction: column;
}

@media (max-width: 900px) {
    .search-wrapper {
      width: 360px;
    }
}

@media (max-width: 600px) {
    .search-wrapper {
        display: none;
    }
}

.search-results {
    flex: 1;
    background-color: #FFFFFF;
    width: 100%;
    box-sizing: border-box;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.10), 0 1px 7px rgba(0, 0, 0, 0.10);
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
    visibility: hidden;
    opacity: 0;
    animation: opacityAnimation 400ms ease-in, visibilityAnimation 400ms ease-in;
    z-index: 10;
    position: relative;
}

@keyframes visibilityAnimation {
    0% {
        visibility: hidden;
    }
    100% {
        visibility: visible;
    }
  }


@keyframes opacityAnimation {
    from {
        opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

.search-results.active {
    visibility: visible;
    opacity: 1;
}

.search-results::-webkit-scrollbar {
    width: 15px;
    border-left: 1px solid #CDCACA;
    border-right: 1px solid #CDCACA;
  }
  
  .search-results::-webkit-scrollbar-thumb {
    cursor: pointer; 
    border-radius: 10px;
    background-color: #CDCACA;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    border-top: 3px solid transparent;
    border-bottom: 3px solid transparent;
    background-clip: content-box;
  }
  
  .search-results::-webkit-scrollbar-track {
    background-color: #FFFBFB;
    border-left: 1px solid #CDCACA;
    border-right: 1px solid #CDCACA;
  }

.radios .result {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;
    cursor: pointer;
}

.radios .result:hover {
    background-color: #F6FBFF;
}

.radios .result .radio-icon {
    width: 32px;
    height: 32px;
}

.radios .result .radio-icon img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
}

.radios .result .radio-name {
    color: #787878;
    font-size: 12px;
    font-weight: 600;
    padding: 0 15px;
    line-height: 23px;
    width: 25%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.radios .result .radio-meta {
    color: #787878;
    font-size: 12px;
    font-weight: 600;
    padding: 0 20px;
    line-height: 23px;
    width: 40%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.radios .result .radio-meta a {
    text-decoration: none;
    font-size: inherit;
    color: inherit;
}

.radios .result .radio-meta a:hover {
    color: var(--primary-color);
    font-weight: 800;
}

.locations,
.categories {
    padding: 15px 12px 10px;
}

.locations .title,
.categories .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 0 15px;
}

.locations .title .icon,
.categories .title .icon {
    width: 32px;
    height: 32px;
}

.locations .title .icon svg,
.categories .title .icon svg {
    width: 100%;
    height: 100%;
}

.locations .title .icon svg g {
    fill: var(--primary-color);
}

.categories .title .icon svg .c1 {
    fill: var(--primary-color);
}

.categories .title .icon svg .c2 {
    fill: #D0E5F8;
}

.locations .title .text,
.categories .title .text {
    font-size: 12px;
    font-weight: 600;
    padding: 8px 15px;
    color: #787878;
}

.locations .result,
.categories .result {
    display: inline-block;
    color: var(--primary-color);
    font-size: 12px;
    font-weight: 500;
    padding: 3px 10px;
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    background-color: #D0E5F8;
    border-radius: 5px;
    margin: 0 10px 7px 0;
}

.locations .result a,
.categories .result a {
    text-decoration: none;
    color: inherit;
    font-size: inherit;
}

.locations .result:hover,
.categories .result:hover {
    cursor: pointer;
    background-color: var(--primary-color);
    color: #FFFFFF;
    font-weight: 500;
}

.overlay {
    background-color: #393939;
    opacity: 0;
    position: unset;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 4;
    transition: opacity 200ms ease-in;
}

.overlay.active {
    position: fixed;
    opacity: 0.8;
}