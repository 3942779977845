.category-widgets {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.category-widgets .category-widget {
    flex: 25%;
    padding: 0 15px;
    box-sizing: border-box;
    margin-bottom: 40px;
}

.category-widgets .category-widget:nth-child(4n+1) {
    padding-left: 0;
}

.category-widgets .category-widget:nth-child(4n+4) {
    padding-right: 0;
}

@media screen and (max-width: 1550px) {
    .category-widgets .category-widget {
        flex: 33.3%;
    }

    .category-widgets .category-widget:nth-child(4n+1),
    .category-widgets .category-widget:nth-child(4n+4) {
        padding: 0 15px;
    }

    .category-widgets .category-widget:nth-child(3n+1) {
        padding-left: 0;
    }
    
    .category-widgets .category-widget:nth-child(3n+3) {
        padding-right: 0;
    }
    
}

@media screen and (max-width: 1100px) {
    .category-widgets .category-widget {
        flex: 50%;
    }

    .category-widgets .category-widget:nth-child(3n+1),
    .category-widgets .category-widget:nth-child(3n+3) {
        padding: 0 15px;
    }
    
    .category-widgets .category-widget:nth-child(2n+1) {
        padding-left: 0;
    }

    .category-widgets .category-widget:nth-child(2n+2) {
        padding-right: 0;
    }
}

@media screen and (max-width: 800px) {
    .category-widgets .category-widget {
        flex: 100%;
        flex-direction: column;
    }

    .category-widgets .category-widget:nth-child(2n+1),
    .category-widgets .category-widget:nth-child(2n+2) {
        padding: 0;
    }
}

.category-widget .category-widget-title {
    font-size: 14px;
    font-weight: 800;
    color: #393939;
    display: block;
    line-height: 25px;
    height: 25px;
    padding: 7px 0px;
}

.category-widget-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    padding: 7px 0px;
    border-radius: 3px;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.10), 0 1px 7px rgba(0, 0, 0, 0.10);
    text-align: center;
    background-color: #f6fbff;
}

.category-widget-list.not-loading {
    background-color: #F5F5F5;
}

.category-widget-list .radio-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    float: left;
    width: 25%;
    cursor: pointer;
    position: relative;
}

/* .category-widget-list .radio-item:hover {
    background-color: #FFFFFF;
} */

.category-widget .radio-item .radio-icon {
    width: 50px;
    height: 50px;
    padding: 5px;
    position: relative;
}

.category-widget .radio-item .name {
    font-weight: 500;
    font-size: 12px;
    color: #787878;
    text-align: center;
    padding: 5px;
    width: calc(100% - 10px);
    line-height: 16px;
    word-wrap: normal;
    text-overflow: ellipsis;
    overflow: hidden;
}

.category-widget .radio-item .cover {    
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(57,57,57, 0.65);
    transition: background-color ease-in 300ms;
    z-index: 2;
    border-radius: 5px;
    visibility: hidden;
}

.category-widget .radio-item:hover .cover,
.category-widget .radio-item.active .cover {
    visibility: visible;
}

.category-widget .radio-item .action svg > * {
    stroke: transparent;
    stroke-width: 0;
}

.category-widget .radio-item .action svg > circle {
    fill: transparent;
}

.skeleton-category-widget-item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    float: left;
    width: 25%;
    background-color: #F5F5F5;
    border-radius: 5px;
    opacity: 1;
    height: 110px;
}

.skeleton-category-widget-item::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
    animation: shimmer 2s infinite; 
    content: '';
}

.skeleton-category-widget-item .skeleton-icon {
    width: 50px;
    height: 50px;
    padding: 5px;
    border-radius: 3px;
    background-color: #DDDBDD;
}

.skeleton-category-widget-item .skeleton-name {
    width: 75%;
    height: 16px;
    background-color: #DDDBDD;
    margin: 7px 20px;
    border-radius: 3px;
}