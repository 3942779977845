.radio-station-list {
    display: flex;
    flex-direction: row;
    column-gap: 25px;
    row-gap: 25px;
    flex-wrap: wrap;
  }
  
  .radio-station-list .radio-item {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 10px 15px;
    background-color: #F6FBFF;
    border-radius: 5px;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.10), 0 1px 7px rgba(0, 0, 0, 0.10);
    position: relative;
    z-index: 1;
    width: 90px;
  }
  
  .radio-item .radio-icon {
    width: 90px;
    height: 90px;
    position: relative;
  }
  
  .radio-item .radio-icon img {
    width: 100%;
    height: 100%;
    /* border-radius: 50%;
    object-fit:contain; */
  }
  
  .radio-item .radio-name {
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      color: #787878;
      text-align: center;
      padding: 10px 0 5px;
      width: 100%;
      overflow:hidden; 
      white-space:nowrap;
      text-overflow: ellipsis;
  }
  
  .radio-item .radio-category {
      width: calc(100% - 20px);
      background-color: #7EC0FC;
      color: #FFFFFF;
      font-size: 12px;
      line-height: 24px;
      text-align: center;
      border-radius: 5px;
      font-weight: 500;
      padding: 0px 10px;
      overflow:hidden; 
      white-space:nowrap;
      text-overflow: ellipsis;
  }
  
  .radio-item .radio-location {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #787878;
    text-align: center;
    padding: 7px 0 0;
    width: 100%;
    overflow:hidden; 
    white-space:nowrap;
    text-overflow: ellipsis;
  }
  
  .radio-item .radio-category a,
  .radio-item .radio-location a {
    text-decoration: none;
    font-size: inherit;
    color: inherit;
  }
  
  .radio-item .cover-icon, 
  .radio-item .cover {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(57,57,57, 0.65);
    transition: background-color ease-in 300ms;
    z-index: 2;
    border-radius: 5px;
    visibility: hidden;
  }

  .radio-item .cover-icon {
    border-radius: 0;
  }

  .radio-item .cover {
    height: 110px;
    border-radius: 5px 5px 0 0;
  }

  .radio-item .cover-icon.active, 
  .radio-item:hover .cover {
    visibility: visible;
  }

  .radio-item:hover .cover-icon {
    visibility: hidden;
  }
  
  .radio-item .action {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 42px;
    height: 42px;
    visibility: hidden;
    opacity: 0;
    transition: opacity ease-in 500ms;
    cursor: pointer;
    z-index: 3;
  }
  
  .radio-item .action.active {
    opacity: 1;
    visibility: visible;
    right: 10px;
  }
  
  .radio-item:hover .action {
    visibility: visible;
    opacity: 1;
  }
  
  .radio-item .action svg {
    width: 100%;
    height: 100%;
  }
  
  .radio-item .action svg path {
    /* stroke:var(--primary-color); */
    stroke: #FFFFFF;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 1.8;
  }
  
  .radio-item .action.is-favourite svg path {
    fill: #FFFFFF;
    /* fill: var(--primary-color); */
  }

  .radio-item:hover .action:not(.playing) {
    animation-name: positionYAnimation;
    animation-duration: 200ms;
    animation-timing-function: ease-in-out;
  }
  
  @keyframes positionYAnimation {
    from {
      top: 65%;
    }
    to {
      top: 50%;
    }
  }

  .radio-item .action svg > * {
    stroke: #FFFFFF;
    stroke-width: 1;
    fill: var(--primary-color);
  }
  
  .radio-item .action svg path {
    fill: white;
    stroke-width: 0;
  }

  .radio-item .is-favourite {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 26px;
    height: 26px;
    visibility: hidden;
    opacity: 0;
    transition: opacity ease-in 500ms;
    cursor: pointer;
    z-index: 3;
  }

  .radio-item:hover .is-favourite {
    visibility: visible;
    opacity: 1;
  }

  .radio-item .is-favourite.selected svg > * {
    stroke: #FFFFFF;
    stroke-width: 1;
    fill: var(--primary-color);
  }

  .radio-item .is-favourite.selected svg path {
    fill: white;
    stroke-width: 0;
  }

  .radio-item .is-favourite:not(.selected) svg path {
    fill: transparent;
    stroke: #FFFFFF;
    stroke-width: 1.8;
  }