.widget-wrapper {
    float: left;
    width: 50%;
    padding: 0 0 50px;
}

@media (max-width: 900px) {
  .widget-wrapper {
    float: none;
    width: 100%;
    padding: 0;
  }
}

.widget {
    padding: 20px 0;
}

.widget.odd {
    padding-right: 20px;
}

@media (max-width: 900px) {
  .widget.odd {
    padding-right: 0;
  }
}

.widget.even {
    padding-left: 20px;
}

@media (max-width: 900px) {
  .widget.even {
    padding-left: 0;
  }
}

.widget .widget-header {
    display: block;
    width: 100%;
    padding: 6px 0;
}

.widget .widget-title {
    float: left;
    font-size: 14px;
    font-weight: 800;
    color: #393939;
    display: block;
}

.widget .see-all-link {
    float: right;
    display: block;
    color: #909090;
    font-size: 14px;
    font-weight: 800;
}

.widget .see-all-link > a {
    color: inherit;
    font-size: inherit;
    text-decoration: none;
}

.clearfix::after {
    content: "";
    display: table;
    clear: both;
  }

.widget-item {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0;
    background-color: #f6fbff;
    margin: 10px 0;
    border-radius: 5px;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.10), 0 1px 7px rgba(0, 0, 0, 0.10);
    /* transition: opacity 0.5s ease; */
    opacity: 1;
}


.widget-item.fade-in {
    opacity: 0;
    animation: fadeInAnimation 1s ease-in forwards;
  }
  
  @keyframes fadeInAnimation {
    to {
      opacity: 1;
    }
  }

.widget-item.fade-out {
    opacity: 1;
    pointer-events: none;
    animation: fadeOutAnimation 750ms ease-in forwards;
}

@keyframes fadeOutAnimation {
    to {
      opacity: 0;
    }
  }

.widget-item .number {
    color: #787878;
    font-size: 14px;
    font-weight: 600;
    width: 50px;
    text-align: center;
}

.widget-item .image {
    width: 36px;
    height: 36px;
    cursor: pointer;
    position: relative;
}

.widget-item .image .cover {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(57,57,57, 0.65);
    transition: background-color ease-in 300ms;
    z-index: 2;
    border-radius: 5px;
    visibility: hidden;
}

.widget-item.active .image .cover,
.widget-item:hover .image .cover {
    visibility: visible;
}

.widget-item .image .action {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 36px;
    height: 36px;
    visibility: hidden;
    opacity: 0;
    transition: opacity ease-in 500ms;
    cursor: pointer;
    z-index: 3;
}

.widget-item.active .image .action {
    display: none;
}

.widget-item.active:hover .image .action {
    display: block;
}

.widget-item .image .action svg > * {
    stroke: transparent;
    stroke-width: 0;
}

.widget-item .image .action svg > circle {
    fill: transparent;
} 

.widget-item .image .music-bar-wrapper {
    position: absolute;
    width: 36px;
    height: 36px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
}

.widget-item.active:hover .image .music-bar-wrapper {
  display: none;
}

.widget-item .image .music-bar-wrapper .music-bars {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.widget-item .image .music-bar-wrapper .music-bars > .bar {
    background-color: #FFFFFF;
}

.widget-item .image > img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
}

.widget-item .name {
    color: #787878;
    font-size: 13px;
    font-weight: 600;
    padding: 0 20px;
    line-height: 23px;
    width: 30%;
    cursor: pointer;
}

@media (max-width: 1500px) {
  .widget-item .name {
    font-size: 14px;
    width: 20%;
  }
}

@media (max-width: 1300px) {
  .widget-item .name {
    width: 30%;
  }
}

@media (max-width: 1024px) {
  .widget-item .name {
    width: 35%;
  }
}

.widget-item .category {
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 600;
    line-height: 23px;
    background-color: #7EC0FC;
    opacity: 1;
    border-radius: 5px;
    padding: 4px 8px;
}

@media (max-width: 1200px) {
  .widget-item .category {
    display: none;
  }
}

@media (max-width: 900px) {
  .widget-item .category {
    display: block;
  }
}

@media (max-width: 767px) {
  .widget-item .category {
    display: none;
  }
}

.widget .category > a {
    text-decoration: none;
    font-size: inherit;
    color: inherit;
}

.widget .action {
    position: absolute;
    width: 30px;
    height: 30px;
    opacity: 0;
    visibility: hidden;
    right: 15px;
    cursor: pointer;
    transition: opacity ease-in 500ms;
}

.widget .action svg {
    fill: var(--primary-color);
}

.widget .action svg,
.widget .is-favourite svg {
    width: 100%;
    height: 100%;
}

  .widget-item .is-favourite {
    position: absolute;
    right: 15px;
    width: 26px;
    height: 26px;
    visibility: hidden;
    opacity: 0;
    transition: opacity ease-in 500ms;
    cursor: pointer;
    z-index: 3;
  }

  @media (max-width: 480px) {
    .widget-item .is-favourite {
      display: none;
    }
  }

  .widget-item:hover .is-favourite {
    visibility: visible;
    opacity: 1;
  }

  .widget-item .is-favourite.selected svg > * {
    stroke: var(--primary-color);
    fill: var(--primary-color);
  }

  .widget-item .is-favourite.selected svg path {
    fill: var(--primary-color);
  }

  .widget-item .is-favourite:not(.selected) svg path {
    fill: transparent;
    stroke: var(--primary-color);
    stroke-width: 1.4;
  }

.widget-item .action.active,
.widget-item:hover .action {
    opacity: 1;
    visibility: visible;
}

.widget-item .action {
    right: 50px;
    width: 30px;
    height: 30px;
}

.widget-item.mob .action {
  right: 15px;
}

@media (max-width: 480px) {
  .widget-item .action {
    right: 15px;
  }
}

.widget-item .action svg > * {
    fill: var(--primary-color); 
  }
  
  .widget-item .action svg path {
    fill: white;
  }
  
  .widget-item .music-bar-wrapper {
    position: absolute;
    right: 75px;
    padding: 0 15px;
  }

  .skeleton-widget-item {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 0;
    background-color: #F5F5F5;
    margin: 10px 0;
    border-radius: 5px;
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.10), 0 1px 7px rgba(0, 0, 0, 0.10);
    opacity: 1;
    height: 36px;
}

.skeleton-widget-item::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  animation: shimmer 2s infinite; 
  content: '';
}

.skeleton-widget-item .skeleton-number {
    color: #787878;
    font-size: 14px;
    font-weight: 600;
    width: 50px;
    text-align: center;
}

.skeleton-widget-item .skeleton-icon {
    width: 36px;
    height: 36px;
    border-radius: 3px;
    background-color: #DDDBDD;
}

.skeleton-widget-item .skeleton-name {
    width: 30%;
    height: 23px;
    background-color: #DDDBDD;
    margin: 0 20px;
    border-radius: 3px;
}

.skeleton-widget-item .skeleton-category {
    width: 20%;
    height: 23px;
    background-color: #DDDBDD;
    margin: 0 20px;
    border-radius: 3px;
}