.shortcut-wrapper {
    grid-area: shortcut;
    z-index: 101;
    background-color: #FFFBFB;
    border-left: 1px solid#CDCACA;
}

@media (max-width: 1700px) {
    .shortcut-wrapper {
        display: none;
    }
}

.shortcuts {
    padding: 30px 20px;
}

.shortcuts .shortcuts-title {
    font-size: 14px;
    font-weight: 800;
    color: #393939;
    padding: 10px 0;
}

.shortcuts .shortcuts-body {
    display: flex;
    flex-direction:row;
    column-gap: 12px;
    row-gap: 14px;
    flex-wrap: wrap;
    padding-top: 3px;
}

.shortcuts .shortcuts-body a {
    text-decoration: none;
}

.shortcuts .shortcut-item {
    /* background-color: #D0E5F8;
    color: #0072DC; */

    background-color: #FFFBFB;
    color: #787878;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.05);


    padding: 9px 10px;
    border-radius: 5px;
    font-size: 13px;
    font-weight: 500;
    display: grid;
    place-items: center;
}

.shortcuts .shortcut-item:hover {
    background-color: var(--primary-color);
    color: #FFFFFF;
}