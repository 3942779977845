.volume-slider {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 158px;
    justify-content:space-between;
    padding: 0 20px;
}

@media (max-width: 480px) {
    .volume-slider {
        width: 112px;
        padding: 0 0 0 12px;
    }
}

input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    width: 120px;
    height: 6px;
    background: linear-gradient(to right, var(--primary-color) 0%, var(--primary-color) 50%, #FFFFFF 50%, #FFFFFF 100%);
    outline: none;
    border-radius: 5px;
}

@media (max-width: 480px) {
    input[type="range"] {
        width: 80px;
    }
}

/***** Track Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type="range"]::-webkit-slider-runnable-track {
    height: 6px;
}
  
/******** Firefox ********/
input[type="range"]::-moz-range-track {
    height: 6px;
}

/***** Thumb Styles *****/
/***** Chrome, Safari, Opera, and Edge Chromium *****/
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    margin-top: -5px; 
    background-color: #FFFFFF;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    border: 3px solid var(--primary-color);
}

/***** Firefox *****/
input[type="range"]::-moz-range-thumb {
    background-color: #FFFFFF;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    border: 3px solid var(--primary-color);
}


/***** Focus Styles *****/
/* Removes default focus */
input[type="range"]:focus {
    outline: none;
}
  
/***** Chrome, Safari, Opera, and Edge Chromium *****/
/* input[type="range"]:focus::-webkit-slider-thumb {
    border: 3px solid #FFFFFF;
    background-color: var(--primary-color);
} */
  
/******** Firefox ********/
/* input[type="range"]:focus::-moz-range-thumb {
    border: 3px solid #FFFFFF;  
    background-color: var(--primary-color);  
} */

.volume-slider .mute {
    width: 32px;
    height: 32px;
    cursor: pointer;
}

@media (max-width: 480px) {
    .volume-slider .mute {
        width: 28px;
        height: 28px;
    }
}

.volume-slider .mute svg {
    width: 32px;
    height: 32px;
}

@media (max-width: 480px) {
    .volume-slider .mute svg {
        width: 28px;
        height: 28px;
    }
}

.volume-slider .mute svg path {
    stroke: var(--primary-color);
    stroke-width: 1.5;
}