.navigation-bar {
  background-color: #FFFBFB;
  border-right: 1px solid#CDCACA;
  grid-area: side;
  overflow-y: auto;
  overflow-x: hidden; 
}

@media (max-width: 600px){
  .navigation-bar {
    position: fixed;
    top: 70px;
    left: 0;
    z-index: 2000;
    height: 100%;
    width: 240px;
    transform: translateX(-100%);
    transition: transform 300ms ease-in 200ms;
  }

  .navigation-bar.active {
    transform: translateY(0);
  }
}

.navigation-bar::-webkit-scrollbar {
  width: 15px;
  border-left: 1px solid #CDCACA;
  border-right: 1px solid #CDCACA;
}

.navigation-bar::-webkit-scrollbar-thumb {
  cursor: pointer; 
  border-radius: 10px;
  background-color: #CDCACA;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  background-clip: content-box;
}

.navigation-bar::-webkit-scrollbar-track {
  background-color: #FFFBFB;
  /* border-left: 1px solid #CDCACA;
  border-right: 1px solid #CDCACA; */
}

.nav {
  padding: 30px 0;
}

@media (max-width: 1300px){
  .nav {
    padding: 25px 0;
  }
}

.nav-section {
  padding: 0 0 60px;
}

.nav-section-title,
.nav-item {
  font-weight: 500;
}

.nav-section-title {
  color: #393939;
  font-size: 14px;
  padding: 10px 25px;
  font-weight: 800;
}

@media (max-width: 1300px) {
  .nav-section-title {
      display: none;
  }
}

@media (max-width: 600px) { 
  .nav-section-title {
    display: block;
  }
}

.nav-item {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.nav-item a {
  display: flex;
  text-decoration: none;
  color: #787878;
  line-height: 20px;
  width: 100%;
  padding: 9px 10px;
  margin: 3px 15px;
}

.nav-item a.active,
.nav-item a:hover {
  background-color: var(--primary-color);
  border-radius: 5px;
  color: #FFFFFF;
}

.nav-icon {
  width: 22px;
  height: 22px;
}

.nav-icon > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.nav-label {
  padding-left: 10px;
  z-index: 2;
  line-height: 22px;
}

@media (max-width: 1300px) {
  .nav-label {
      display: none;
  }
}

@media (max-width: 600px) {
  .nav-label {
      display: block;
  }
}

.nav-icon svg {
  width: 100%;
  height: 100%;
}

.home-icon g,
.location-icon g,
.trending-icon g {
  fill: var(--primary-color);
}

.heart-icon path,
.music-icon path {
  fill: var(--primary-color);
}

.categories-icon .c1 {
  fill: var(--primary-color);
}

.categories-icon .c2 {
  fill: #D0E5F8;
}

.nav-item a.active .nav-icon .home-icon g,
.nav-item a:hover .nav-icon .home-icon g {
  fill: #FFFFFF;
}

.nav-item a.active .nav-icon .categories-icon g .c1,
.nav-item a.active .nav-icon .categories-icon g .c2,
.nav-item a:hover .nav-icon .categories-icon g .c1, 
.nav-item a:hover .nav-icon .categories-icon g .c2 {
  fill: #FFFFFF;
}

.nav-item a.active .nav-icon .location-icon g,
.nav-item a:hover .nav-icon .location-icon g {
  fill: #FFFFFF;
}

.nav-item a.active .nav-icon .trending-icon g,
.nav-item a:hover .nav-icon .trending-icon g {
  fill: #FFFFFF;
}

.nav-item a.active .nav-icon .heart-icon path,
.nav-item a:hover .nav-icon .heart-icon path {
  fill: #FFFFFF;
}

.nav-item a.active .nav-icon .music-icon path,
.nav-item a:hover .nav-icon .music-icon path {
  fill: #FFFFFF;
}