.header {
    /* position: fixed;
    width: 100%; */
    background-color: var(--primary-color);;
    z-index: 1600;
    grid-area: head;
    position: sticky;
    top: 0;
}

.header .header-inner {
    height: auto;
    padding: 18px 20px;
    display: flex;
    justify-content: center;
}

@media (max-width: 900px) {
  .header .header-inner {
    justify-content: end;
  }
}

.header .logo {
    position: absolute;
    top: 18px;
    left: 25px;
    font-size: 19px;
    font-weight: 600;
    text-align: left;
    color: #FFFFFF;
    line-height: 34px;
  }

  .header .logo a {
    text-decoration: none;
    color: inherit;
    font: inherit;
  }

  .header .menu-icon {
    display: none;
    position: absolute;
    top: 18px;
    right: 25px;
    width: 28px;
    height: 28px;
    padding: 3px 5px;
    cursor: pointer;
    background-color: #FFFFFF;
    border-radius: 5px;
  }

  .header .menu-icon svg {
    width: 100%;
    height: 100%;
  }

  .header .menu-icon svg path {
    stroke: var(--primary-color);
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
  }

@media (max-width: 600px) {
    .header .menu-icon {
        display: block;
    }
}